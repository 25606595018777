const $ = require('jquery');
$(function () {
    $('.sb-search-submit').click(function () {
        if ($(this).hasClass('shadow-12')) {
            $(this).removeClass('shadow-12');
        } else {
            $(this).addClass('shadow-12');
        }
        let classInput = $('#sb-search-input-block');
        if (classInput.hasClass('sb-search-input')) {
            classInput.removeClass('sb-search-input');
            classInput.addClass('sb-search-input-active');
            classInput.addClass('shadow-12');
        } else {
            classInput.addClass('sb-search-input');
            classInput.removeClass('sb-search-input-active');
            classInput.removeClass('shadow-12');
        }
    });
});