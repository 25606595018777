const $ = require("jquery");
window.jQuery = $;
window.$ = $;

// import 'bootstrap';
// import * as Popper from '@popperjs/core';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/collapse';

import '/src/page/index/scss/styles.scss';

import '/src/js/search';